<template>
    <v-row justify="center">
    <v-dialog
      v-model="show_duplicate"
      max-width="1200"
    >
      <v-card>
        <v-card-title class="text-h5">
          <v-row>

            <v-col cols="11">
              <v-alert
      color="primary"
      dark
      border="left"
      prominent
    >
      <h4><strong>{{sender}}</strong> :: <strong>{{subject}}</strong> </h4>
    </v-alert>

            </v-col>
            <v-col cols="1">
              <v-btn
                  v-show="!hidden"
                  color="blue"
                  v-on:click="hidePopup"
                  fab
                  dark
                  small
                  top
                  right
                  class="mt-5"
                >
                  X
                </v-btn>
              <!-- <span v-on:click="hidePopup"></span> -->
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <!-- left side -->
            <v-col cols="6">
              <v-row>
                <v-col cols="12">
                  <v-card
                    color="#385F73"
                    dark
                    class="pa-10"
                    style="height:127px"
                  >
                    <strong>ID</strong> : <span>{{original_muid}}</span>
                </v-card>

                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-card
                    color="#385F73"
                    dark
                    class="pa-5"
                  >
                    <div class="original original_content" id="original_content" v-html="original_html"></div>
                </v-card>

                </v-col>
              </v-row>
            </v-col>
            <!-- right side -->
            <v-col cols="6">
              <v-row>
                <v-col cols="12">
                  <v-card
                    color="#385F73"
                    dark
                    class="pa-5"
                  >
                      <v-select
                        :items="duplicateOptions"
                        label="Duplicates"
                        outlined
                        v-model="duplicate_id"
                      ></v-select>
                </v-card>

                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-card
                    color="#385F73"
                    dark
                    class="pa-5"
                  >
                    <div class="duplicate duplicate_content" id="duplicate_content" v-html="duplicate_html"></div>
                </v-card>

                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>

</template>
<script>
export default {
    name: 'DeduplicationCheck',
    data: () => ({
        readClass: "",
        duplicate_id: ''
    }),
    mounted() {
      let view = this
      view.duplicate_id = view.duplicate_muids[0]
      view.$store.dispatch('Deduplicate/setDuplicateForId', '').then(function(){
        view.fetchDeduplicate(view, view.duplicate_id, 'original');
      });
      view.$store.dispatch('Deduplicate/setOriginalHtml', '').then(function(){
        view.fetchDeduplicate(view, view.dedupObject.ID, 'gmail_client')
      });
    },
    computed: {
      duplicateOptions: function(){
        let duplicates = this.dedupObject.duplicates.split(',')
        let options = []
        duplicates.forEach((o)=>{
          options.push({text:o,value:o})
        })
        return options
      },
      duplicate_muids : function(){
        return this.dedupObject.duplicates.split(',')
      },
      original_muid: function(){
        return this.dedupObject.ID
      },
      original_html: function(){
        return this.$store.getters['Deduplicate/getOriginalHtml']
      },
      subject: function(){
        return this.dedupObject.email_subject
      },
      sender: function(){
        return this.dedupObject.original_sender_name
      },
      duplicate_html: function(){
        return this.$store.getters['Deduplicate/getDuplicateForId']
      }
    },
    props: [
        "dedupObject",
        "show_duplicate"
    ],
    methods: {
      hidePopup: function(){
        this.$emit('hidePopup', true)
      },
      fetchDeduplicate: function (view, id, type) {
        view.$fetch({ requiresAuth: true, operation : 'add' , vueScope: view })
            .post(view.$url('DEDUPLICATES_SEARCH'), JSON.stringify({ "id": id, type: type }), {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
        .then(function (res) {
          let response = res.data
            typeof response.payload !== 'undefined' ? (response.type === 'original' ? view.$store.dispatch('Deduplicate/setDuplicateForId', response.payload) : view.$store.dispatch('Deduplicate/setOriginalHtml', response.payload)) : ''
        }).catch(function (e) {
            console.log(e)
        })
    },
    },
    watch: {
      duplicate_id : function(o){
        let view = this
         view.fetchDeduplicate(view, o, 'original')
      }
    }
}
</script>
