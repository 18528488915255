<template>
  <div class="container">
    <div class="text-right">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>
    </div>

<div class="text-left">
    <v-container fluid>
        <v-row >
         <v-col cols="6" >
          <v-text-field
                    label="Search"
                    ref="input_query"
                    outlined
                    dense
                    clearable
                    prepend-inner-icon="mdi-search-web"
                    v-model="searchQuery"
                />
          </v-col>
          <v-col cols="4" >
            <v-btn big color="primary" @click.prevent="search">
              Search
        </v-btn>
        <v-btn big  color="primary" @click.prevent="clearSearch">
              Clear
        </v-btn>
         </v-col>
       </v-row>
       <v-row no-gutters>
                <v-col :cols="6">
                    <v-row align="center" no-gutters>
                        <v-col cols="2" no-gutters>
                            <v-checkbox label="ID" ref="fontsize2" v-model="sesrchById"/>
                        </v-col>
                        <v-col cols="2">
                            <v-checkbox label="Subject" ref="fontsize3" v-model="searchBySubject"/>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
     </v-container>
</div>
        <!-- v-data-table  -->
    <v-row>
      <v-col cols="12">
          <div class="tableWrapper">
            <v-data-table
              :headers="headers"
              item-key="product_id"
              :page.sync="currentPage"
              :items="deduplicates"
              :options.sync="options"
              :server-items-length="totRecords"
              :loading="isLoading"
              class="elevation-1"
              hide-default-footer
              @page-count="pageCount = $event"
            >
              <template v-slot:item.custom_id="{ item }">
                <v-list-item-content @click.prevent="showDuplicates(item)">{{item.ID}}</v-list-item-content>
              </template>
              <template v-slot:item.custom_email_subject="{ item }">
                <v-list-item-content class="deduplicate_item__2"  @click.prevent="showDuplicates(item)">{{ellespingSubject(item.email_subject)}}</v-list-item-content>
              </template>
              <template v-slot:item.custom_original_sender_name="{ item }">
                <v-list-item-content class="deduplicate_item__3"  @click.prevent="showDuplicates(item)">{{item.original_sender_name}}</v-list-item-content>
              </template>
              <template v-slot:item.total_duplicates="{ item }">
                <p>{{item.duplicates.split(',').length}}</p>
              </template>
            </v-data-table>
            <div class="text-center pt-4 pb-4">
              <Pagination
            :isloading="isLoading"
            :startRecord="startRecord"
            :currentPage="currentPage"
            :lastPage="lastPage"
            :lastRecord="lastRecord"
            :totRecords="totRecords"
            :isCurrentPageClass="isCurrentPageClass"
            :perpage="perpage"
            :getLastPageClass="getLastPageClass"
            :totPage="totPage"
            :getPages="getPages"
            @handlePerPage="handlePerPage"
            @paginate="paginate"
            @last="last"
            @getDataByPage="getDataByPage"
            :showPerPage="showPerPage"
             />
            </div>

            <div></div>
          </div>
      </v-col>
    </v-row>
    <!-- v-data-table-ends -->
     <v-card v-if="show_duplicate">
      <DeduplicateCheck  @hidePopup="hidePopup" :show_duplicate="show_duplicate" :dedupObject="dedupObject" />
    </v-card>

  </div>
</template>

<script>
import axios from "axios";
import Index from "./Index.vue";
import Buttons from "./component/Buttons.vue";
import Item from "../../components/base/Item.vue";
import Pagination from "./component/Pagination.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import DeduplicateCheck from './DeduplicateCheck'

export default {
  components: { Index, Buttons, Loading, Pagination, DeduplicateCheck },
  data() {
    return {
      //////table data starts here////
      options: {},
      isLoading: true,
      fullPage: true,
      sort: "",
      //  minDate: "2020-01-05",
      //  maxDate: "2019-08-30",
      headers: [
        {
          text: "ID",
          value: "custom_id",
          width: "200px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Subject",
          value: "custom_email_subject",
          width: "200px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Sender",
          value: "custom_original_sender_name",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Original Sent Date",
          value: "original_date",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Total Duplicates",
          value: "total_duplicates",
          width: "150px",
          sortable: false,
          class: "v-data-table-header",
        },
      ],
      ////// table data ends here
      checkbox: false,
      affinity: [],
      checkbox1: false,
      dialog: false,
      dialog1: false,
      dialog2: false,
      query: "",
      checked: false,
      //pagination code
      // perpage: 50,
      // totRecords: null,
      // page: 1,
      // isLoading: true,
      // fullPage: true,
      showPerPage: true,
      subject_length: 125,
      ///
      orders: [
                    {'key': 'ID', 'val': true},
                    {'key': 'original_date', 'val': true}
                ],
                enableSorting: false,
                show_duplicate: false,
                dedupObject: false
    };
  },
  computed: {
    //pagination computed methods
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    perpage: function () {
       return this.$store.getters['Deduplicate/getPerPage']
    },
    totPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },
    totRecords: function () {
            var store = this.$store
            return store.getters['Deduplicate/getTotalDeduplicates'] == '' ? 0 : store.getters['Deduplicate/getTotalDeduplicates']
        },
    currentPage: function () {
      return this.$store.getters['Deduplicate/getCurrentPage']
    },
    lastPage: function () {
      return this.$store.getters['Deduplicate/getLastPage']
    },
    ///////
    // total_count: function () {
    //         return this.dedup.duplicates.split(',').length
    //     },
    deduplicates: function () {
        return this.allDeduplicates()
    },
    searchQuery: {
            get:function() {
                return this.$store.getters['Deduplicate/getSearchQuery']
            },
            set: function(o) {
                this.$store.dispatch('Deduplicate/setSearchQuery', o)
            }
        },
        sesrchById: {
            get:function() {
                return this.$store.getters['Deduplicate/getSearchById']
            },
            set: function(o) {
                this.$store.dispatch('Deduplicate/setSetSearchBySubject', false)
                this.$store.dispatch('Deduplicate/setSearchQuery', '')
                this.$store.dispatch('Deduplicate/setSearchById', o)
            }
        },
        searchBySubject: {
            get:function() {
                return this.$store.getters['Deduplicate/setSetSearchBySubject']
            },
            set: function(o) {
                this.$store.dispatch('Deduplicate/setSearchById', false)
                this.$store.dispatch('Deduplicate/setSearchQuery', '')
                this.$store.dispatch('Deduplicate/setSetSearchBySubject', o)
            }
        }
  },
  methods: {
    dedupClass: function(i) {
        return parseInt(i)%2 === parseInt(0) ? 'grey lighten-2' : 'grey lighten-5'
    },
    showDuplicates: function(dedupObject){
      this.dedupObject = dedupObject
      this.show_duplicate = !this.show_duplicate
    },
    hidePopup: function(flag){
      if(flag){
        this.show_duplicate = false
      }
    },
    fetchDuplicates: function(flag){
      if(flag){
        this.show_duplicate = false
      }
    },
      ellespingSubject: function(subject){
        return (subject != null)?((subject.length > this.subject_length)?`${subject.substring(0, this.subject_length)}...`:subject):'';
      },
    search: function() {
            this.searchDeduplication(this)
        },
        clearSearch: function() {
            var view = this
            view.$store.dispatch('Deduplicate/setIsFromSearch', false).then(function () {
                return view.$store.dispatch('Deduplicate/setSearchQuery', '')
            }).then(function() {
                return view.$store.dispatch('Deduplicate/setSearchById', true)
            }).then(function() {
                return view.$store.dispatch('Deduplicate/setSetSearchBySubject', false)
            }).then(function() {
                return view.$store.dispatch('Deduplicate/setPerPage', 10)
            }).then(function() {
                return view.$store.dispatch('Deduplicate/setPage', 1)
            }).then(function() {
                view.getMuids()
            })
        },
    allDeduplicates() {
                if (this.enableSorting) {
                    this.enableSorting = false
                    return this.$store.getters['Deduplicate/getResponsePayload']
                } else {
                    return this.$store.getters['Deduplicate/getResponsePayload']
                }
            },
    checkIfOperationExistForModule(type){
        return this.$utils.checkIfOperationExistForModule(this, type)
    },
    //pagination methods
    getDataByPage(page) {
      let view = this
      view.$store.dispatch("Deduplicate/setPage", page).then(function () {
                    return view.$store.dispatch("Deduplicate/setIsLoading", true);
                }).then(function () {
                    view.getMuids();
                }).catch(function (e) {
                    console.log(e);
                });
    },
    handlePerPage(p) {
      let view = this
      view.$store.dispatch("Deduplicate/setPerPage", p).then(function () {
                    return view.$store.dispatch("Deduplicate/setPage", parseInt(1));
                }).then(function () {
                  return view.$store.dispatch("Deduplicate/setIsLoading", true);
                }).then(function () {
                  view.getMuids();
                }).catch(function (e) {
                  console.log(e);
                });
    },
    paginate(n) {
      console.log(n);
      let view= this
      if (parseInt(n) === parseInt(0) && parseInt(this.currentPage) === parseInt(1)) {
                return;
            }
            if (parseInt(n) === parseInt(-1)) {
                if (parseInt(this.currentPage) <= parseInt(1)) {
                    return;
                }
            }
            if (parseInt(n) === parseInt(1)) {
                if (parseInt(this.currentPage) >= parseInt(this.lastPage)) {
                    return;
                }
            }
        let myPromise = new Promise(function (resolve, reject) {
                    resolve(view.$store.dispatch("Deduplicate/setPage", n === 0 ? 1 : parseInt(view.currentPage) + parseInt(n)));
                    reject("Error: on set page");
                });
                myPromise.then(function () {
                    view.$store.getters['Deduplicate/getIsFromSearch'] ? this.searchDeduplication(view) : view.getMuids();
                }).catch(function (e) {
                    console.log(e);
                });
    },
    last() {
      let view = this
      if (parseInt(view.lastPage) === parseInt(view.currentPage)) {
                    return;
                }
                view.$store.dispatch("Deduplicate/setPage", view.lastPage).then(function () {
                    view.$store.dispatch("Deduplicate/setIsLoading", true);
                }).then(function () {
                    view.getMuids();
                }).catch(function (e) {
                    console.log(e);
                });
    },
    /////
    getMuids() {
      let view = this
      view.$store.dispatch('Deduplicate/setStartTime', new Date().getTime()).then(function () {
            view.isLoading = true
            return view.$store.dispatch('Deduplicate/setIsLoading', true)
        }).then(function () {
            return view.$store.dispatch('Deduplicate/setResponsePayload', [])
        }).then(function () {
            view.$fetch({ requiresAuth: true, operation : 'add' , vueScope: view })
            .post(view.$url('DEDUPLICATES'), JSON.stringify(view.$store.getters['Deduplicate/getRequestPayload']), {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(function (res) {
                let response =  res.data
                view.isLoading = false
                view.$store.dispatch('Deduplicate/setEndTime', new Date().getTime())
                view.$store.dispatch('Deduplicate/setIsLoading', false)
                typeof response.payload !== 'undefined' ? view.$store.dispatch('Deduplicate/setResponsePayload', response.payload) : ''
                typeof response.hasNext !== 'undefined' ? view.$store.dispatch('Deduplicate/setHasNext', response.hasNext) : ''
                typeof response.totalDedups !== 'undefined' ? view.$store.dispatch('Deduplicate/setTotalDeduplicates', response.totalDedups) : ''
                typeof response.lastPage !== 'undefined' ? view.$store.dispatch('Deduplicate/setLastPage', response.lastPage) : ''
            }).catch(function (e) {
                console.log(e)
            })
        })
    },
    searchDeduplication(view) {
        view.isLoading = true
        view.$store.dispatch('Deduplicate/setIsFromSearch', true).then(function () {
            return view.$store.dispatch('Deduplicate/setStartTime', new Date().getTime())
        }).then(function () {
            return view.$store.dispatch('Deduplicate/setIsLoading', true)
        }).then(function () {
            return view.$store.dispatch('Deduplicate/setResponsePayload', [])
        }).then(function () {
            view.$fetch({ requiresAuth: true, operation : 'add' , vueScope: view })
            .post(view.$url('DEDUPLICATES_SEARCH'), JSON.stringify({
                    search: view.$store.getters['Deduplicate/gerSearchPayload'],
                    payload: view.$store.getters['Deduplicate/getRequestPayload'],
                }), {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(function (res) {
                view.isLoading = false
                let response = res.data
                view.$store.dispatch('Deduplicate/setEndTime', new Date().getTime())
                view.$store.dispatch('Deduplicate/setIsLoading', false)
                typeof response.payload !== 'undefined' && Array.isArray(response.payload) ? view.$store.dispatch('Deduplicate/setResponsePayload', response.payload) : []
                typeof response.hasNext !== 'undefined' ? view.$store.dispatch('Deduplicate/setHasNext', response.hasNext) : ''
                typeof response.tot_deduplicates !== 'undefined' ? view.$store.dispatch('Deduplicate/setTotalDeduplicates', response.tot_deduplicates) : ''
                typeof response.lastPage !== 'undefined' ? view.$store.dispatch('Deduplicate/setLastPage', response.lastPage) : ''
            }).catch(function (e) {
                console.log(e)
            })
        })
    },
    searchAffinity: function () {
      this.getMuids()
    },
  },
  watch: {
  '$store.state.Rawdata.current_active_module': function(o) {
      if(o){
            this.getMuids();
      }
    },
 },
};
</script>
